import React from "react"
import Masthead from '../components/Masthead'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlockSimple from '../components/BlockSimple'
import FeatureCards from "../components/FeatureCards"


const features = [{
    title: "Airport Shuttle Services",
    image: "/buseta.jpg",
    description: "We offer airport shuttle services on any day of the week for the best price. Schedule your service and enjoy a comfortable, secure and timely pick up or drop off."
  },
  {
    title: "Mid day tour & shuttle service",
    image: "/mono.jpeg",
    description: "At the scheduled time the shuttle will pick you or your group up, take you to the destination of your first tour and wait for you while you explore. Once  everyone is finished exploring the shuttle will take you to your following location. Food and bathroom stops are made as often as needed. After you tours are done the shuttle will drop you off at the destination of your choice."
  },
  {
    title: "Full day tour & shuttle service",
    image: "/bridge.jpg",
    description: "At the scheduled time the shuttle will pick you or your group up, take you to the destination of your first tour and wait for you while you explore. Once  everyone is finished exploring the shuttle will take you to your following location and wait until pick time to then take you the selected restaurant for lunch. Food and bathroom stops are made as often as needed. After lunch the shuttle will take you the any other destinatios agreed on. After your tours are all done the shuttle will drop you off at the destination of your choice."
  }
]

const AboutPage = () => (
  <Layout>
    <div className="">
      <SEO title="Costa Rica Best Choice" />
      <div className="masthead__wrapper">
        <div className="masthead-shuttle"></div>
      </div>
      <div className="block-simple__bg">
        <div className="block-simple__wrapper">
          <h1 className="block-simple__heading">Services</h1>
          <div className="block-simple__text">We provide a safe, reliable and timely transportation service throughout the Guanacaste region.</div>
          <div className="block-simple__text">Costa Rica Best Choice offers a high end and trusty trnsportation service. Using new and comfortable vehicles we guarantee timely and effective pick up and drop offs throughout the whole central and northern region. We strive to think about all the details to make your trip pleasant and easy.</div>
        </div>
      </div>

      <div className="feature-card__wrapper-sm ">
        <div className="feature-card">
            <FeatureCards features={features}/>
        </div>
      </div>

    </div>
  </Layout>
)

export default AboutPage
